import * as React from "react";
import { Link } from "gatsby";
import Layout from '../components/Layout';
import WorkHeaderImage from '../svg/Medical-BG5-v2.svg';
import OurProcessImage from '../images/our-process.jpg';
import OurProcessImage2 from '../images/our-process2.jpg';
import Branding1 from '../images/client-logos/clientlogo1Glossy.jpg';
import Branding2 from '../images/client-logos/clientlogo2Glossy.jpg';
import Branding3 from '../images/client-logos/clientlogo3Glossy.jpg';
import Branding4 from '../images/client-logos/clientlogo4Glossy.jpg';
import Branding5 from '../images/client-logos/clientlogo5Glossy.jpg';
import Branding6 from '../images/client-logos/clientlogo6Glossy.jpg';
import Web1 from '../images/clientWeb1.jpg';
import Web2 from '../images/clientWeb2.jpg';
import Web3 from '../images/clientWeb3.jpg';
import Web4 from '../images/clientWeb4.jpg';
import WebApp1 from '../images/dental-book-1.jpg';
import WebApp2 from '../images/dental-book-2.jpg';
import SEO from '../components/Seo';
import CTA from "../components/CTA";

const Work = () => {

    return(
        <Layout>
            <SEO title="Work" description={" We’ve had the pleasure of working with amazing healthcare providers and we've helped them reach their digital potential."}/>
            <div className="pt-10 pb-16 bg-blue-100 sm:pt-16 lg:pt-8 lg:pb-14 lg:overflow-hidden">
                <div className="mx-auto max-w-7xl lg:px-8">
                    <div className="lg:grid lg:grid-cols-1 lg:gap-8">

                        <div className="mx-auto max-w-md px-4 sm:max-w-2xl sm:px-6 sm:text-center lg:px-0 lg:text-center lg:flex lg:items-center">
                        <div className="lg:py-24 text-center">
                    
                            <h1 className="mt-4 text-5xl tracking-tight font-extrabold text-white sm:mt-5 sm:text-6xl lg:mt-6 xl:text-6xl">
                            <span className="block text-blue-800">Our <span className="text-blue-500">Work</span> </span>
                            </h1>

                            <p className="mt-3 text-lg text-blue-900 sm:mt-5 sm:text-xl lg:text-lg xl:text-xl">
                                We’ve had the pleasure of working with amazing healthcare providers and we've helped them reach their digital potential.
                            </p>

                            <div className="mt-10">
                                <Link to="/contact-us" className="inline-flex px-5 py-3 border border-transparent text-base justify-center font-medium rounded-md shadow-sm text-blue-900 hover:text-white bg-gradient-to-r from-white to-white hover:from-blue-700 hover:to-blue-700">
                                    Work With Us
                                </Link>
                            </div>
                        </div>
                        </div>

                        {/* <div className="lg:relative">
                        <div className="mx-auto max-w-md px-4 sm:max-w-2xl sm:px-6 lg:max-w-none lg:px-0">
                            <WorkHeaderImage className="w-full h-auto lg:absolute lg:inset-y-0" alt=""/>
                        </div>
                        </div> */}

                    </div>
                </div>
            </div>

            {/* BRANDING OVERVIEW*/}
            <section>
                <div className="bg-white">
                <div className="mx-auto py-12 px-4 max-w-7xl sm:px-6 lg:px-8 lg:py-24">
                    <div className="space-y-5 sm:space-y-4 max-w-3xl mx-auto text-center pb-10">
                        <h2 className="text-3xl font-extrabold tracking-tight sm:text-4xl text-blue-800">Healthcare Branding</h2>
                    </div>

                    <div className="space-y-12 lg:grid lg:grid-cols-3 lg:gap-8 lg:space-y-0">

                        <div className="lg:col-span-3">
                            <ul className="space-y-12 sm:grid sm:grid-cols-3 sm:gap-x-6 sm:gap-y-12 sm:space-y-0 lg:gap-x-8">
                            <li>
                                <div className="space-y-4">
                                    <div className="aspect-w-3 aspect-h-2">
                                        <img className="object-cover shadow-lg rounded-lg" src={Branding1} alt="Dr. Kennedy Family Dental Logo"/>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div className="space-y-4">
                                    <div className="aspect-w-3 aspect-h-2">
                                        <img className="object-cover shadow-lg rounded-lg" src={Branding2} alt="Dental Book Logo"/>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div className="space-y-4">
                                    <div className="aspect-w-3 aspect-h-2">
                                        <img className="object-cover shadow-lg rounded-lg" src={Branding3} alt="Muse Dental Logo"/>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div className="space-y-4">
                                    <div className="aspect-w-3 aspect-h-2">
                                        <img className="object-cover shadow-lg rounded-lg" src={Branding4} alt="River District Physio Logo"/>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div className="space-y-4">
                                    <div className="aspect-w-3 aspect-h-2">
                                        <img className="object-cover shadow-lg rounded-lg" src={Branding5} alt="River District Medical Logo"/>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div className="space-y-4">
                                    <div className="aspect-w-3 aspect-h-2">
                                        <img className="object-cover shadow-lg rounded-lg" src={Branding6} alt="River District Dentistry Logo"/>
                                    </div>
                                </div>
                            </li>
                            </ul>
                        </div>
                    </div>
                </div>
                </div>
            </section>


            {/* WEB DESIGN SECTION */}
            <section>
                <div className="bg-white">
                <div className="mx-auto py-12 px-4 max-w-7xl sm:px-6 lg:px-8 lg:py-24">
                    <div className="space-y-5 sm:space-y-4 max-w-3xl mx-auto text-center pb-10">
                        <h2 className="text-3xl font-extrabold tracking-tight sm:text-4xl text-blue-800">Healthcare Website Design & Development</h2>
                    </div>

                    <div className="space-y-12 lg:grid lg:grid-cols-2 lg:gap-8 lg:space-y-0">

                        <div className="lg:col-span-3">
                            <ul className="space-y-12 sm:grid sm:grid-cols-2 sm:gap-x-6 sm:gap-y-12 sm:space-y-0 lg:gap-x-8">
                            <li>
                                <div className="space-y-4">
                                    <div className="aspect-w-3 aspect-h-2">
                                        <img className="object-cover shadow-lg rounded-lg" src={Web1} alt="River District Dentistry Website"/>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div className="space-y-4">
                                    <div className="aspect-w-3 aspect-h-2">
                                        <img className="object-cover shadow-lg rounded-lg" src={Web2} alt="River District Dentistry Mobile Website"/>
                                    </div>
                                </div>
                            </li>
                           
                            </ul>
                        </div>

                        <div className="lg:col-span-3">
                            <ul className="space-y-12 sm:grid sm:grid-cols-2 sm:gap-x-6 sm:gap-y-12 sm:space-y-0 lg:gap-x-8">
                            
                            <li>
                                <div className="space-y-4">
                                    <div className="aspect-w-3 aspect-h-2">
                                        <img className="object-cover shadow-lg rounded-lg" src={Web3} alt="River District Physiotherapy Website"/>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div className="space-y-4">
                                    <div className="aspect-w-3 aspect-h-2">
                                        <img className="object-cover shadow-lg rounded-lg" src={Web4} alt="River District Physiotherapy Mobile Website"/>
                                    </div>
                                </div>
                            </li>
                           
                            </ul>
                        </div>

                    </div>
                </div>
                </div>
            </section>

            {/* WEB APPLICATION SECTION */}
            <section>
                <div className="bg-white">
                <div className="mx-auto py-12 px-4 max-w-7xl sm:px-6 lg:px-8 lg:py-24">
                    <div className="space-y-5 sm:space-y-4 max-w-3xl mx-auto text-center pb-10">
                        <h2 className="text-3xl font-extrabold tracking-tight sm:text-4xl text-blue-800">Healthcare Startups & Web Applications</h2>
                    </div>

                    <div className="space-y-12 lg:grid lg:grid-cols-1 lg:gap-8 lg:space-y-0">

                        <div className="lg:col-span-1">
                            <ul className="space-y-12 sm:grid sm:grid-cols-1 sm:gap-x-6 sm:gap-y-12 sm:space-y-0 lg:gap-x-8">
                            <li>
                                <div className="space-y-4">
                                    <div className="">
                                        <img className="w-full object-cover shadow-lg rounded-lg" src={WebApp1} alt="River District Dentistry Website"/>
                                    </div>
                                </div>
                            </li>
                           
                            </ul>
                        </div>

                        <div className="lg:col-span-1">
                            <ul className="space-y-12 sm:grid sm:grid-cols-1 sm:gap-x-6 sm:gap-y-12 sm:space-y-0 lg:gap-x-8">
                            
                            <li>
                                <div className="space-y-4">
                                    <div className="aspect-w-3 aspect-h-2">
                                        <img className="w-full object-cover shadow-lg rounded-lg" src={WebApp2} alt="River District Physiotherapy Website"/>
                                    </div>
                                </div>
                            </li>
                           
                            </ul>
                        </div>

                    </div>
                </div>
                </div>
            </section>

             {/* CTA */}
             <CTA/>
            

        </Layout>
        
    )
}

export default Work;